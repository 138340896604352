<template>
    <div>
        <el-form ref="form" :model="form">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item class="mb-2" label="Серийный номер">
                        <el-input
                            v-model="form.name"
                            placeholder="Серийный номер"
                        ></el-input>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Тип устройства">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Тип устройства"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Группа продуктов">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Группа продуктов"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Марка (Бренд)">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Марка (Бренд)"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Продукт">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item class="mb-2" label="Тип гарантии">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Тип гарантии"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="Дата начала гарантии">
                        <el-date-picker
                            type="date"
                            placeholder="Дата начала гарантии"
                            v-model="form.date1"
                            style="width: 100%"
                        ></el-date-picker>
                    </el-form-item>

                    <el-form-item label="Дата истечения срока гарантии">
                        <el-date-picker
                            type="date"
                            placeholder="Дата истечения срока гарантии"
                            v-model="form.date1"
                            style="width: 100%"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer text-center">
            <el-button type="primary" @click="onSubmit">
                Сохранить и закрыть
            </el-button>
            <el-button type="danger" @click="dialogReject = false">
                Закрыть
            </el-button>
        </span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
        };
    },
};
</script>
