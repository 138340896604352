<template>
    <div>
        <el-form ref="form" :model="form">
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-button class="w-100" type="primary">
                        Описание неисправности
                    </el-button>
                </el-col>
                <el-col :span="8">
                    <el-button class="w-100" type="warning">
                        Возможная причина
                    </el-button>
                </el-col>
                <el-col :span="8">
                    <el-button class="w-100" type="danger"> Решение </el-button>
                </el-col>
            </el-row>

            <el-row :gutter="20">
                <el-col :span="8">
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="8">
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                    <el-card class="box-card mt-4">
                        <div>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
        };
    },
};
</script>
