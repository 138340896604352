<template>
    <div>
        <el-form ref="form" :model="form">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item class="mb-2" label="Тип">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Тип"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Фамилия">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Имя">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Пол">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Пол"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Телефон 1">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Телефон 2">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item class="mb-2" label="ИНН">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Электронная почта">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Регион">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Регион"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Район">
                        <el-select
                            class="w-100"
                            v-model="form.region"
                            placeholder="Район"
                        >
                            <el-option
                                label="Zone one"
                                value="shanghai"
                            ></el-option>
                            <el-option
                                label="Zone two"
                                value="beijing"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item class="mb-2" label="Комментарий">
                        <el-input
                            type="textarea"
                            v-model="form.desc"
                        ></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer text-center">
            <el-button type="danger" @click="dialogReject = false">
                Отклонить заявку
            </el-button>
        </span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {},
        };
    },
};
</script>
